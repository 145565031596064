:root {
  --sat: env(safe-area-inset-top);
  --sar: env(safe-area-inset-right);
  --sab: env(safe-area-inset-bottom);
  --sal: env(safe-area-inset-left);
}

#root {
  height: 100%;
}

@media print {
  #sidenav {
    display: none !important;
  }
}

@media (prefers-reduced-motion: reduce) {
  *,
  ::before,
  ::after {
    transition: none !important;
    animation-delay: -1ms !important;
    animation-duration: 1ms !important;
    animation-iteration-count: 1 !important;
    background-attachment: initial !important;
    scroll-behavior: auto !important;
    transition-duration: 0s !important;
    transition-delay: 0s !important;
  }
}

#hubspot-messages-iframe-container {
  color-scheme: none;
  z-index: 5 !important;
}

@media screen and (max-width: 62em) {
  #hubspot-messages-iframe-container {
    display: none !important;
    left: -9999999999px;
  }
}

body {
  font-feature-settings: 'kern';
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  text-rendering: optimizeLegibility;
  overflow: hidden;
  /*
  
 min-height: 100vh !important;
  min-height: calc(var(--vh, 1vh) * 100) !important;

  min-height: -webkit-fill-available !important;
  */

  /*
min-height: 100vh !important;
  min-height: calc(var(--vh, 1vh) * 100) !important;

  --
  min-height: calc(var(--vh, 1vh) * 100);
   background: green !important;
  */

  padding-top: env(safe-area-inset-top);
}

body.pwa-standalone {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

html {
  scroll-behavior: smooth;
}

.mobileheight {
  height: 100%;
  overflow-y: hidden;
  position: relative;
}

.mobileheight {
  /*
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  */
}
/*
.fullpage-container {
height: 100vh;
height: -webkit-fill-available;

}
*/

svg {
  display: inline !important;
}

.qr-code {
  border-radius: 8px;
}

body.pii .pii-data {
  filter: blur(3px);
}

.recharts-cartesian-axis-tick {
  font-size: 12px;

  /**
  color: #bcbcbc;
 */
}

.recharts-cartesian-axis-tick-value {
  text-align: left;
}

.preview-holder iframe {
  border: 0;
}

.ReportToolTip {
  background-color: #fff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.09);
  padding: 5px 10px;
}

.ReportToolTip strong {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 13px;
  color: black;
}

.ReportToolTip h3 {
  margin: 0;
  font-size: 22px;
  font-weight: 500;
}

.recharts-text.recharts-cartesian-axis-tick-value {
  fill: var(--chakra-colors-default);
}

.recharts-text.recharts-pie-label-text {
  font-weight: 900;
}

.has-help {
  margin-right: 15px;
}

/**
INTEGRATIONMODAL
*/

.integration-body__content {
  padding: 24px !important;
}

.integration-body {
  overflow: hidden;
}

.integration-body__content header {
  font-weight: 900;
  font-size: 14px;
}

.integration-body__content .integration-body__action {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0;
}

.integration-body__content .integration-body__action button {
  font-weight: 700;
}

.second-nav-button .chakra-button__icon {
  font-size: 18px;
}

.scaledown {
  zoom: 0.5;
}

.scaledown iframe {
  transform: scale(0.5);
  transform-origin: 0 0;
}

.chakra-modal__body .header-image {
  border-top-left-radius: var(--chakra-radii-md);
  border-top-right-radius: var(--chakra-radii-md);
}

.card-table .chakra-table__container,
.card-table .pagination,
.breakout {
  width: calc(100% + var(--chakra-space-12) * 2);
  margin-left: calc(var(--chakra-space-12) * -1);
  max-width: calc(100% + var(--chakra-space-12) * 2);
}

@media screen and (max-width: 48em) {
  .breakout {
    width: calc(100% + var(--chakra-space-4) * 2);
    margin-left: calc(var(--chakra-space-4) * -1);
    max-width: calc(100% + var(--chakra-space-4) * 2);
  }
}

.overlap-top {
  margin-top: -42px;
}


.chakra-input__left-element + input {
  padding-left: var(--chakra-sizes-10);
}